<template>
  <div class="animated">
    <CustomModal
      :modalTitle="$t('delete')"
      :button1Title="$t('delete')"
      button1Class="btn-danger"
      :show="removeModal"
      @hide="removeModal = false"
      @onButton1Click="remove()"
    >
      {{ $t("termDelete") }}
    </CustomModal>
    <b-card no-body>
      <b-card-header>
        <i class="fa fa-filter mr-1"></i>
        {{ $t("filter") }}
        <div class="card-header-actions">
          <BrandButton
            :text="$t('newTerm')"
            @click="add()"
            v-if="$auth.hasAuth($options, 'add')"
          ></BrandButton>
        </div>
      </b-card-header>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <i class="fa fa-filter mr-1"></i>
        {{ $t("filter") }}
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col sm="12">
            <v-server-table
              :columns="columns"
              v-if="$auth.hasAuth($options, 'list')"
              ref="table"
              :options="options"
              id="dataTable"
            >
              <template slot="child_row" slot-scope="props">
                <div v-html="props.row.description" class="text-left p-3"></div>
              </template>
              <template slot="buttons" slot-scope="props">
                <div class="action-buttons nowrap">
                  <b-button
                    variant="primary icon"
                    v-b-tooltip.hover
                    :title="$t('edit')"
                    class="mr-1 btn-brand"
                    v-if="$auth.hasAuth($options, 'edit')"
                  >
                    <i class="fa fa-edit" @click="edit(props.row.id)"></i>
                  </b-button>
                  <b-button
                    variant="danger icon"
                    v-b-tooltip.hover
                    :title="$t('delete')"
                    class="mr-1 btn-brand"
                    v-if="$auth.hasAuth($options, 'remove')"
                  >
                    <i
                      class="fa fa-trash"
                      @click="
                        () => {
                          removeModal = true;
                          selectedId = props.row.id;
                        }
                      "
                    ></i>
                  </b-button>
                </div>
              </template>
            </v-server-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import BrandButton from "../../widgets/form/CustomBrandButton";
import { ServerTable } from "vue-tables-2";
import CustomModal from "../../widgets/form/CustomModal.vue";
import gridOptions from "../../shared/grid-options";
import http from "../../shared/http";
import toast from "../../shared/toast-helper";
export default {
  name: "TermList",
  components: {
    BrandButton,
    ServerTable,
    CustomModal,
  },
  methods: {
    getList() {
      this.$refs.table.setPage(1);
    },
    showRemoveModal(id) {
      this.removeModal = true;
      this.selectedId = id;
    },
    remove() {
      http.post(`terms/delete?id=${this.selectedId}`).then((response) => {
        if (response.success) {
          this.getList();
          this.removeModal = false;
          this.selectedId = 0;
          toast.success(response.message);
        }
      });
    },
    edit(id) {
      this.$router.push({ path: `term-add/${id}` });
    },
    add() {
      this.$router.push({ path: `term-add` });
    },
  },
  watch: {
    filters: {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  data() {
    return {
      removeModal: false,
      columns: ["key", "value", "url", "buttons"],
      options: {
        ...gridOptions.getAll(),
        pagination: { chunk: 10 },
        perPageValues: [10, 25, 50, 100],
        requestFunction: (data) => {
          let path = "terms/getlistbypaging";
          if (!data.orderBy) {
            data.orderBy = "id";
            data.ascending = 0;
          }
          return http
            .get(path, { params: data })
            .catch(function (e) {
              this.dispatch("error", e);
            })
            .finally(() => {});
        },
        sortable: "key,value,url",
        headings: {
          key: this.$t("termKey"),
          url: this.$t("url"),
          value: this.$t("value"),
          description: this.$t("description"),
          buttons: this.$t("operations"),
        },
      },
    };
  },
};
</script>


